import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Button, Badge, } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { toastr } from 'react-redux-toastr';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./PrescriptionDataTable";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import ReactTooltip from 'react-tooltip';
import EditPrescriptionModal from '../../newComponents/utilities/EditPrescriptionModal';
import DownloadPrescriptionModal from '../../newComponents/utilities/DownloadPrescriptionModal';
import EmailPharmacyModal from '../../newComponents/utilities/EmailPharmacyModal';
import { GlobalState } from '../../contextAPI/Context';

const Tables = (props) => {
  // CONTEXT VARIABLES 
  const {
    setToken,
    setSpinner,
    prescriptionFromDate,
    prescriptionToDate,
    prescriptionPatientName,
    prescriptionPatientEmail,
    prescriptionStatus,
    prescriptionPharmacyId,
    prescriptionProviderId,
    setPrescriptionFromDate,
    setPrescriptionToDate,
    setPrescriptionPatientName,
    setPrescriptionPatientEmail,
    setPrescriptionStatus,
    setPrescriptionPharmacyId,
    setPrescriptionProviderId,
    setBlankStateMessage,
    prescriptionReload,
    setPrescriptionRowData,
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let history = useHistory();
  const [rowData, setRowData] = useState("") // eslint-disable-line
  const [patientList, setPatients] = useState([]);
  const [appointmentId, setAppointmentId] = useState("");
  const [loading, setLoading] = useState(true);
  const [editPrescriptionModal, setEditPrescriptionModal] = useState(false);
  const [downloadPrescriptionModal, setDownloadPrescriptionModal] = useState(false);
  const [downloadConfirmation, setDownloadConfirmation] = useState(false);
  const [emailPharmacyModal, setEmailPharmacyModal] = useState(false);


  const fetchPrescription = () => {
    console.log("Data Fetched")
    setToken(props.authtoken);
    setLoading(true);
    setSpinner(true);

    let localFromDate = moment().subtract(14, 'days').startOf('day')
    let utcFromDate = moment.utc(localFromDate).format('YYYY-MM-DD HH:mm')

    let localToDate = moment().endOf('day')
    let utcToDate = moment.utc(localToDate).format('YYYY-MM-DD HH:mm')

    const params =
    {
      "from_date": prescriptionFromDate ? prescriptionFromDate : utcFromDate,
      "to_date": prescriptionToDate ? prescriptionToDate : utcToDate,
      "patient_name": prescriptionPatientName ? prescriptionPatientName : "",
      "pharmacy_id": prescriptionPharmacyId ? prescriptionPharmacyId : "",
      "provider_id": prescriptionProviderId ? prescriptionProviderId : "",
      "patient_email": prescriptionPatientEmail ? prescriptionPatientEmail : "",
      "prescription_status": prescriptionStatus ? prescriptionStatus : 0,
      "is_pagination": 0,
      "offset": "force"
    }

    axios.post(API_ENDPOINT + "/user/get-prescription-data/", params, {
      headers: {
        Authorization: props.authtoken,
      }
    }).then((res) => {
      setPatients(res.data);
      setBlankStateMessage(res.data.length > 0 ? false : true);
      setLoading(false);
      setSpinner(false);
    });
  };

  const resetFields = () => {
    console.log("Reset Applied")
    setPrescriptionFromDate("")
    setPrescriptionToDate("")
    setPrescriptionPatientName("")
    setPrescriptionPatientEmail("")
    setPrescriptionStatus("")
    setPrescriptionPharmacyId("")
    setPrescriptionProviderId("")
}

  const downloadAttachment = (appointmentId) => {
    setSpinner(true);
    const params = {
      appointment_id: appointmentId,
      note_type: 5,
      is_view:1
    };
    axios
      .post(API_ENDPOINT + "/user/view-appointment-notes/", params, {
        headers: {
          Authorization: props.authtoken,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            response["headers"]["x-suggested-filename"] || "report.pdf"
          );
          toastr.success("Done", "Document downloaded successfully");
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  const dateFormatter = (cell, row) => {
    return `${cell ? moment(cell).utc(cell).local().format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A"}`
  }

  // APPOINTMENT SLOT START AND END TIME 
  const slotFormatter = (cell, row) => {
    return `${cell
      ? `${moment(row.schStartTime)
        .utc(row.schStartTime)
        .local()
        .format("h:mm A")} - ${moment(row.schEndTime)
          .utc(row.schEndTime)
          .local()
          .format("h:mm A")}`
      : "N/A"}`
  }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const statusFormatter = (cell) => {
    let color = cell ? "success" : "danger"
    let label = cell ? "Active" : "Inactive"

    return (
      <p><Badge style={{fontSize:'11px', width:'auto'}} className="pharmacy-badge" color={color} title={label}>{label}</Badge></p>
    )
  }

  const prescriptionStatusFormatter = (cell, row) => {
    let color = ""
    let label = row.prescription_status

    switch (row.prescription_status_id) {
      case 29:
        color = "success"
        break;
      case 30:
        color = "warning"
        break;
      case 31:
        color = "danger"
        break;
      case 32:
        color = "warning"
        break;
      case 33:
        color = "warning"
        break;
      default:
        color = "primary"
    }

    return (
      <p><Badge style={{fontSize:'11px', width:'auto'}} className="prescription-status-badge" color={color} title={label}>{label}</Badge></p>
    )
  }

  const statusFormatterForCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const actionFormatter = (cell, row) => {
    return (
      <div className="prescription-action-button-container">
        <Button
          className="money-button"
          title="View Prescription info"
          outline
          onClick={() => {
            setPrescriptionRowData(row)
            history.push(`/view-prescription-info?aid=${btoa(row.appointment_id)}`);
          }}
        >
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle"
            title="View Prescription info"
          />
        </Button>
        <Button
          className="email-button"
          title="Download Prescription"
          outline
          onClick={() => {
            setDownloadPrescriptionModal(true)
            setAppointmentId(row.appointment_id)
          }}
        >
          <FontAwesomeIcon
            icon={faDownload}
            fixedWidth
            className="align-middle"
            title="Download Prescription"
          />
        </Button>
      </div>
    )
  }

  const addressFormatter = (cell, row) => {
    let address = row.pharmacy_address ? `${row.pharmacy_address}` : "N/A"
    let city = row.pharmacy_city ? `, ${row.pharmacy_city}` : ""
    let state = row.pharmacy_state ? `, ${row.pharmacy_state}` : ""
    let country = row.pharmacy_country ? `, ${row.pharmacy_country}` : ""
    let zip = row.pharmacy_zip ? `, ${row.pharmacy_zip}` : ""

    let fullAddress = `${address}${city}${state}${country}${zip}`
    
    return (
      <p className="exclamation-wrapper">
        <span className="email-exclamation" data-tip={fullAddress}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        {basicValidation(cell)}
        <ReactTooltip className="doc-upload-tooltip" />
      </p>
    )
  }

  const emailFormatter = (cell, row) => {
    return (
      <p className="exclamation-wrapper">
        <span className="email-exclamation" data-tip={row.patient_email}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        {basicValidation(cell)}
        <ReactTooltip className="doc-upload-tooltip" />
      </p>
    )
  }

  const tableColumns = [
    {
      dataField: "schStartTime",
      text: "Appt Date",
      sort: true,
      formatter: dateFormatter,
      csvFormatter: dateFormatter
    },
    {
      dataField: "schStartTime",
      text: "Appt Slot",
      sort: false,
      formatter: slotFormatter,
      csvFormatter: slotFormatter
    },
    {
      dataField: "patient_name",
      text: "Patient Name",
      sort: true,
      formatter: emailFormatter,
      csvFormatter: basicValidation
    },
    {
      dataField: "patient_email",
      text: "Patient Email",
      sort: true,
      csvExport: true,
      hidden: true,
      csvFormatter: basicValidation
    },
    {
      dataField: "provider_name",
      text: "Care Provider Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation
    },
    {
      dataField: "pharmacy_name",
      text: "Pharmacy Name",
      sort: true,
      formatter: addressFormatter,
      csvFormatter: basicValidation,
    },
    {
      dataField: "pharmacy_email",
      text: "Pharmacy Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "pharmacy_efax",
      text: "Pharmacy Efax",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "pharmacy_address",
      text: "Pharmacy Address",
      sort: false,
      csvExport: true,
      hidden: true,
      csvFormatter: basicValidation,
    },
    {
      dataField: "pharmacy_contactNumber",
      text: "Pharmacy Phone",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation
    },

    {
      dataField: "prescription_status",
      text: "Prescription Status",
      sort: true,
      formatter: prescriptionStatusFormatter,
      csvFormatter: basicValidation
    },
    {
      dataField: "pharmacy_status",
      text: "Pharmacy Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterForCSV
    },
    {
      text: "Actions",
      formatter: actionFormatter,
      csvExport: false
    }
  ];

  let loader = null;

  if (loading) {
    loader = <Spinner />;
  }

  // DEFAULT API CALL
  useEffect(() => { resetFields(fetchPrescription())}, []);// eslint-disable-line
  useEffect(() => { fetchPrescription(resetFields)}, [prescriptionFromDate, prescriptionToDate, prescriptionPatientName, prescriptionPharmacyId, prescriptionProviderId, setBlankStateMessage, prescriptionReload,]);// eslint-disable-line
  useEffect(() => { if (downloadConfirmation) downloadAttachment(appointmentId) }, [downloadConfirmation]);// eslint-disable-line
  useEffect(() => { if (downloadPrescriptionModal) setDownloadConfirmation(false) }, [downloadPrescriptionModal]);// eslint-disable-line

  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Prescriptions</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Prescriptions</BreadcrumbItem>
          </Breadcrumb>
        </Header>

        <EditPrescriptionModal
          rowData={rowData}
          editPrescriptionModal={editPrescriptionModal}
          setEditPrescriptionModal={setEditPrescriptionModal}
          token={props.authtoken}
        />

        <DownloadPrescriptionModal
          downloadPrescriptionModal={downloadPrescriptionModal}
          setDownloadPrescriptionModal={setDownloadPrescriptionModal}
          setDownloadConfirmation={setDownloadConfirmation}
        />

        <EmailPharmacyModal
          rowData={rowData}
          emailPharmacyModal={emailPharmacyModal}
          setEmailPharmacyModal={setEmailPharmacyModal}
          setSpinner={setSpinner}
        />
        <ExportCSVTable data={patientList} columns={tableColumns} />
      </Container>
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(Tables);
