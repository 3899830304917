import React, { useContext, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import classNames from "classnames";
import { IconContext } from "react-icons";
import { MdDashboard,MdMedicalServices } from "react-icons/md";
import { FaPrescriptionBottleAlt, FaUserMd,FaPills,FaHandshake, FaCalendarCheck } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { GiHeartBottle } from "react-icons/gi";
import { RiNurseFill, RiHandHeartFill, RiCoupon2Fill, RiSettings4Fill,RiMoneyDollarBoxFill } from "react-icons/ri";
import { GlobalState } from "../contextAPI/Context";
import "./Layout.css";
import ReactTooltip from "react-tooltip";


import equipologo from "../assets/img/logos/equipo_logo_060117_white.png";
import equiposmalllogo from "../assets/img/logos/NVC_Equipo_LOGO.png";



const category = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <MdDashboard />,
  },
  {
    name: "Patients",
    path: "/patients",
    icon: <HiUsers />,
  },
  {
    name: "Doctors",
    path: "/doctors",
    icon: <FaUserMd />,
  },
  {
    name: "Nurses",
    path: "/nurses",
    icon: <RiNurseFill />,
  },
  {
    name: "Therapists",
    path: "/therapists",
    icon: <GiHeartBottle />,
  },
  {
    name: "Carers",
    path: "/carers",
    icon: <RiHandHeartFill />,
  },
  {
    name: "Appointments",
    path: "/appointments",
    icon: <FaCalendarCheck />,
  },
  {
    name: "Prescriptions",
    path: "/prescriptions",
    icon: <FaPrescriptionBottleAlt />,
  },
  {
    name: "Pharmacy",
    path: "/pharmacy",
    icon: <MdMedicalServices />,
  },
  {
    name: "Insurances",
    path: "/insurances",
    icon: <RiMoneyDollarBoxFill />,
  },
  {
    name: "Coupons",
    path: "/coupons",
    icon: <RiCoupon2Fill />,
  },
  {
    name: "Healthcare Partners",
    path: "/healthcare-Partners",
    icon: <FaHandshake />,
  },
  {
    name: "Medication",
    path: "/medication",
    icon: <FaPills />,
  },
  {
    name: "System Settings",
    path: "/system-settings",
    icon: <RiSettings4Fill />,
  },
];
const SidebarItem = ({ name, icon, to }) => {
  return (
    <>
      <ReactTooltip className="card-tooltip" id="side-bar" />
      <li className="sidebar-item" data-tip={name} data-for="side-bar">
        <NavLink
          exact
          to={to}
          className="sidebar-link"
          activeStyle={{ color: "black" }}
          activeClassName="side-bar-active-link"
        >
          <IconContext.Provider
            value={{
              className: "align-middle mr-2",
            }}
          >
            {icon}
          </IconContext.Provider>
          <span className="align-middle side-bar-text">{name}</span>
        </NavLink>
      </li>
    </>
  );
};

const Sidebar = (props) => {
  // GLOBAL CONTEXT VARIABLES  
  const { hideMenu, setHideMenu,setPhysicianEmail,setPhysicianSpeciality, setPhysicianName,setPhysicianStatus} = useContext(GlobalState)

  // SIDEBAR AUTO HIDE LOGIC
  let location = useLocation()
  const handleSidebarToggle = () => {
    if (location.pathname === "/dashboard") {setHideMenu(true)}
    if (location.pathname === "/patients") {setHideMenu(true)}
    if (location.pathname === "/doctors") {setHideMenu(true)}
    if (location.pathname === "/appointments") {setHideMenu(true)}
    if (location.pathname === "/coupons") {setHideMenu(true)}
    if (location.pathname === "/appointments/") {setHideMenu(true)}
    if (location.pathname === "/coupons") {setHideMenu(true)}
    if (location.pathname === "/insurances") {setHideMenu(true)}
    // console.log('pathname', location.pathname)
  }

  useEffect(() => { handleSidebarToggle();setPhysicianName("");setPhysicianEmail("");setPhysicianSpeciality("");setPhysicianStatus(""); }, [location]) // eslint-disable-line
  useEffect(() => { setHideMenu(true) }, []) // eslint-disable-line
  
  return (
    <>
      <nav className={classNames("sidebar", hideMenu && "toggled")}>
        <div  className="sidebar-content">
          <a className={classNames("sidebar-brand")} href="/">
          {hideMenu && window.innerWidth > 1024 ? (
              <img src={equiposmalllogo} className="img-fluid" alt="logo" />
            ) : (
              <img
                src={equipologo}
                alt="Admin App Logo"
                className="img-fluid"
                width="200"
                style={{width:125, height:55,marginRight:20}}
              />
            )}

          </a>
          <ul className="sidebar-nav">
            {category.map((category, index) => (
              <SidebarItem
                key={index.toString()}
                name={category.name}
                to={category.path}
                icon={category.icon}
              />
            ))}
          </ul>

          {/* POWERED BY EQUIPO */}
          {/* <div className="equipo-logo">
            {hideMenu ? "" : <p className="powered-by">powered by</p>}
            <a href="https://equipo.io/" target="_blank" rel="noopener noreferrer">
              <img
                src={equipoLogo}
                className="img-fluid mb-2"
                alt="equipo logo"
              />
            </a>
          </div> */}

        </div>
      </nav>
    </>
  );
};

export default Sidebar;
