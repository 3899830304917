import React, { useState, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';

const CorporateGroupFilters = (props) => {
    // CONTEXT VARIABLES 
    const {
        spinner,
        setCorporateName,
        setCorporateStatus,
        setCorporateAddress,
        corporateListReload,
        setCorporateListReload,

    } = useContext(GlobalState)


    // LOCAL VARIABLES 
    const animatedComponents = makeAnimated();
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")

    //  STATUS 
    const [status, setStatus] = useState("")
    const statusDropDown = [
        { value: "1", label: 'Active' },
        { value: "0", label: 'Inactive' }

    ]

    const runSearch = () => {
        if (name || address || status) {
            setCorporateName(name ? name : "")
            setCorporateAddress(address ? address : "")
            setCorporateStatus(status ? status.value : "")
            setCorporateListReload(corporateListReload + 1)
        }
    }

    const resetFields = () => {
        setName("")
        setAddress("")
        setStatus("")
        setCorporateName("")
        setCorporateAddress("")
        setCorporateStatus("")
    }

    return (
        <AvForm>
            <AvGroup>
                <Row >
                    <Col xs="2">
                        <Label>Name</Label>
                        <AvField
                            type="text"
                            name="name"
                            placeholder="All"
                            value={name}
                            validate={{
                                maxLength: { value: 120 }
                            }}
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </Col>
                    <Col xs="2">
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="All"
                            value={status}
                            options={statusDropDown}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col xs="5" >
                        <Label>Address</Label>
                        <AvField
                            type="text"
                            name="address"
                            placeholder="All"
                            value={address}
                            validate={{
                                maxLength: { value: 500 }
                            }}
                            onChange={(e) => { setAddress(e.target.value) }}
                        />
                    </Col>
                    <Col xs="3" className="action-btn-filter" style={{paddingTop: '10px'}}>
                        <Button
                            type="submit"
                            className="go-buttons button-corporate"
                            disabled={spinner ? true : false}
                            onClick={() => { runSearch() }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons button-corporate"
                            disabled={spinner ? true : false}
                            onClick={() => { resetFields() }}
                        >Reset</Button>
                    </Col>
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default CorporateGroupFilters;
